*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body{
    background: var(--color-bg);
}
a{
    color: unset;
    text-decoration: none;
}
.gradient__container{
    display: relative;
    width: 100%;
    height: 660px;
    background: radial-gradient(circle at 3% 25%, rgba(0,0,0,1) 42%, transparent, transparent);
}
.video__container{
    position: absolute;
    height: 660px;
    z-index: -1;
    top: 0;
    right: 0;
}
.video{
    width: 100%;
    height: 100%;
}
.gradient__bg{
    /* global 92%+ browsers support */
    /* background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 1%, rgba(4, 12, 24, 1) 100%); */
    background: radial-gradient(circle at 3% 25%, rgba(0,0,0,0.8) 1%, transparent);
}
.gradient__text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.section__padding{
    padding: 4rem 6rem;
}
.section__margin{
    margin: 4rem 6rem;
}

/* scale up animation start */
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
/* scale up animation end */

@media screen and (max-width: 1024px){
    .gradient__container{
        display: relative;
        width: 100%;
        height: 660px;
        background: radial-gradient(circle at 3% 25%, rgba(0,0,0,1) 42%, transparent, transparent);
    }
    .video__container{
        position: absolute;
        height: 660px;
        z-index: -1;
        top: 0;
        right: 0;
        overflow-x: hidden;
    }
    .video{
        width: 120%;
        height: 100%;
    }
}

@media screen and (max-width: 700px){
    .section__padding{
        padding: 4rem;
    }
    .section__margin{
        margin: 4rem;
    }
}
@media screen and (max-width: 550px){
    .section__padding{
        padding: 4rem 2rem;
    }
    .section__margin{
        margin: 4rem 2rem;
    }
}